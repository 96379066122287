<template>
  <v-container fluid>
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-none d-sm-flex"
    >
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-chart-bar-stacked"
          title="Event"
          value="2"
          style="cursor: pointer;"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-redo-variant"
          title="Akan Datang"
          value="2"
          style="cursor: pointer;"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-update"
          title="Berjalan"
          value="1"
          style="cursor: pointer;"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-undo-variant"
          title="Terlewat"
          value="1"
          style="cursor: pointer;"
        />
      </v-col>
    </v-row>
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <!--  -->
      <v-col
        cols="12"
        style="padding: 0"
      >
        <!--  -->
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              :color="item.color"
              :content="item.summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    :color="item.color"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Summary',
    data () {
      return {
        summary: {
          total: 0,
          open: 0,
          close: 0,
          deleted: 0,
        },
        items: [
          {
            tab: 'One',
            title: 'Event',
            color: 'warning',
            icon: 'mdi-chart-bar-stacked',
            summary: '0',
            status: '',
          },
          {
            tab: 'Two',
            title: 'Akan Datang',
            color: 'rgb(233, 30, 99)',
            icon: 'mdi-redo-variant',
            summary: '0',
            status: 'upcoming',
          },
          {
            tab: 'Three',
            title: 'Berjalan',
            color: 'success',
            icon: 'mdi-update',
            summary: '0',
            status: 'ongoing',
          },
          {
            tab: 'Four',
            title: 'Terlewat',
            color: 'info',
            icon: 'mdi-undo-variant',
            summary: '0',
            status: 'missed',
          },
        ],
        tab: null,
      }
    },
  }
</script>

<style></style>
