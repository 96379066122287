<template>
  <div>
    <!-- <div>
      <v-alert
        v-model="alert"
        border="left"
        close-text="Close Alert"
        color="deep-purple accent-4"
        dark
        dismissible
      >
        {{ title }}
      </v-alert>
      <div class="text-center">
        <v-btn
          v-if="!alert"
          color="deep-purple accent-4"
          dark
          @click="alert = false"
        >
          Reset
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
  import firebase from 'firebase/app'
  import 'firebase/messaging'
  export default {
    name: 'NotificationBox',

    components: {},

    data () {
      return {
        alert: false,
        title: '',
        from: '',
        subject: '',
        userimg: '',
        messaging: firebase.messaging(),
        currentMessage: '',
        notify: false,
      }
    },

    computed: {},

    created () {
      this.receiveMessage()
    },

    methods: {
      receiveMessage () {
        this.messaging.onMessage(payload => {
          console.log(payload) //
          // eslint-disable-next-line no-unused-vars
          var notify
          notify = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: payload.notification.icon,
            tag: 'Dummy',
          })
          console.log(payload.notification)
          const title =
            payload.notification.title +
            '<br>' +
            '<hr>' +
            payload.notification.body

          this.$toast.info(title, {
            type: 'info',
            position: 'top-right',
            duration: 3000,
            dismissible: true,
          })
          // update
          this.getMsg()
        })
      },
      getMsg () {
        this.$emit('getMsg')
      },

      setNotificationBoxForm (title, from, subject) {
        this.title = title
        this.from = from
        this.subject = subject
        this.notify = true
        this.alert = true
      },
      closed () {
        this.notify = false
      },
    },

    validations: function () {
      return {}
    },
  }
</script>

<style>
.notification-box {
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 30%;
  height: 100px;
  background-color: #fff;
  z-index: 999;
}
#notification-close {
  position: absolute;
  color: #777;
  font: 14px;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 2px;
  z-index: 10;
  cursor: pointer;
}

.notification-data {
  margin: 0 20px;
}
.notification-data p {
  margin: 10px 0;
}
.notification-notice p {
  font-size: 14px;
  font-weight: 400;
}
.notification-title p {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}
.notification-time small {
  font-size: 12px;
}

.notification-wall-number small {
  font-size: 8px;
}
img {
  width: 30px;
}
</style>
