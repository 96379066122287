import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      '',
    drawer: null,
    token: localStorage.getItem('access_token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
  },
  getters: {
    loggedIn (state) {
      return state.token !== null
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    retrieveToken (state, token) {
      state.token = token
    },
    destroyToken (state) {
      state.token = null
      state.user = null
    },
    getUser (state, user) {
      state.user = user
    },
  },
  actions: {
    register (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/user/register/byadmin', {
            name: data.name,
            email: data.email,
            phone: data.phone,
            username: data.username,
            region_id: data.region_id,
            note: data.note,
            community_id: 1,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    registerCommunity (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/community', {
            name: data.name,
            status: data.status,
            prefix: data.prefix,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem('access_token')
            context.commit('destroyToken')
            reject(error)
          })
      })
    },
    registerForum (context, data) {
      axios.defaults.headers.common.Authorization =
      'Bearer ' + context.state.token
      return new Promise((resolve, reject) => {
        axios
          .post('https://nc.notiva.net/v1/forum/thread', {
            title: data.title,
            post: data.post,
            cover_id: 3,
            thread_category_id: 1,
            status: data.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem('access_token')
            context.commit('destroyToken')
            reject(error)
          })
      })
    },
    registerEvent (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/post/event', {
           title: data.title,
           thumbnail_id: 2,
           image_id: 1,
           post: data.post,
           featured: 1,
           community_id: 1,
           sorting: 99,
           status: data.status,
           start_at: data.start_at,
           end_at: data.end_at,
           location: data.location,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem('access_token')
            context.commit('destroyToken')
            reject(error)
          })
      })
    },
    destroyToken (context) {
      axios.defaults.headers.common.Authorization =
        'Bearer ' + context.state.token
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get('https://nc.notiva.net/v1/user/auth/logout')
            .then(response => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('user')
              context.commit('destroyToken')
              resolve(response)
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('user')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },
    retrieveToken (context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://nc.notiva.net/v1/user/auth/promin', {
            account: credentials.username,
            password: credentials.password,
            community_id: 1,
          })
          .then(response => {
            if (response.data.meta.status === true) {
              const token = response.data.data.access_token
              localStorage.setItem('access_token', token)
              context.commit('retrieveToken', token)
              resolve(response)
            } else if (response.data.meta.status === false) {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUser (context) {
      axios.defaults.headers.common.Authorization =
        'Bearer ' + context.state.token
      return new Promise((resolve, reject) => {
        axios
          .get('https://nc.notiva.net/v1/user/me')
          .then(response => {
            if (response.data.meta.status === true) {
              const user = JSON.stringify(response.data.data)
              localStorage.setItem('user', JSON.stringify(response.data.data))
              context.commit('getUser', user)
              resolve(response)
            } else if (response.data.meta.status === false) {
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
})
