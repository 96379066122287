import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/auth/Login'),
      meta: {
        requiresVisitor: true,
      },
    },
    {
      name: 'Logout',
      path: '/logout',
      component: () => import('@/views/auth/Logout'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
        // Aktifitas
        {
          name: 'IndexAktifitas',
          path: '/aktifitas',
          component: () => import('@/views/dashboard/pages/aktifitas/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ShowAktifitas',
          path: '/aktifitas/:id/show',
          component: () => import('@/views/dashboard/pages/aktifitas/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        // Kategori
        {
          name: 'IndexNewsCategory',
          path: '/kategori/news',
          component: () =>
            import('@/views/dashboard/pages/category/CategoryNews'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'IndexEventCategory',
          path: '/kategori/event',
          component: () =>
            import('@/views/dashboard/pages/category/CategoryEvent'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateCategory',
          path: '/kategori/create',
          component: () => import('@/views/dashboard/pages/category/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ShowCategori',
          path: '/kategori/:id/show',
          component: () => import('@/views/dashboard/pages/category/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        // Region
        {
          name: 'IndexArea',
          path: '/region',
          component: () => import('@/views/dashboard/pages/region/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateArea',
          path: '/region/create',
          component: () => import('@/views/dashboard/pages/region/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'EditArea',
          path: '/region/:id/edit',
          component: () => import('@/views/dashboard/pages/region/Edit'),
          meta: {
            requiresAuth: true,
          },
        },
        // Konfirmasi
        {
          name: 'IndexKonfirmasi',
          path: '/konfirmasi',
          component: () => import('@/views/dashboard/pages/konfirmasi/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        // Community
        {
          name: 'IndexCommunity',
          path: '/community',
          component: () => import('@/views/dashboard/pages/community/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateCommunity',
          path: '/community/create',
          component: () => import('@/views/dashboard/pages/community/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ShowCommunity',
          path: '/community/:id/show',
          component: () => import('@/views/dashboard/pages/community/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        // Forum
        {
          name: 'Forum',
          path: '/forum',
          component: () => import('@/views/dashboard/pages/forum/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'EditForum',
          path: '/forum/:id/edit',
          component: () => import('@/views/dashboard/pages/forum/Edit'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ShowForum',
          path: '/forum/:id/show',
          component: () => import('@/views/dashboard/pages/forum/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateForum',
          path: '/forum/create',
          component: () => import('@/views/dashboard/pages/forum/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        // Event
        {
          name: 'ShowEvent',
          path: '/event/:id/show',
          component: () => import('@/views/dashboard/pages/event/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ListEvent',
          path: '/event',
          component: () => import('@/views/dashboard/pages/event/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateEvent',
          path: '/event/create',
          component: () => import('@/views/dashboard/pages/event/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        //  News
        {
          name: 'ShowNews',
          path: '/news/:id/show',
          component: () => import('@/views/dashboard/pages/news/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ListNews',
          path: '/news',
          component: () => import('@/views/dashboard/pages/news/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateNews',
          path: '/news/create',
          component: () => import('@/views/dashboard/pages/news/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        // polling
        {
          name: 'IndexPolling',
          path: '/polling',
          component: () => import('@/views/dashboard/pages/polling/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreatePolling',
          path: '/polling/create',
          component: () => import('@/views/dashboard/pages/polling/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'EditPolling',
          path: '/polling/:id/edit',
          component: () => import('@/views/dashboard/pages/polling/Edit'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'ShowPolling',
          path: '/polling/:id/show',
          component: () => import('@/views/dashboard/pages/polling/Show'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'VotePolling',
          path: '/polling/:id/vote',
          component: () => import('@/views/dashboard/pages/polling/Vote'),
          meta: {
            requiresAuth: true,
          },
        },
        // Anggota
        {
          name: 'IndexAnggota',
          path: '/anggota',
          component: () => import('@/views/dashboard/pages/anggota/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'CreateAnggota',
          path: '/anggota/create',
          component: () => import('@/views/dashboard/pages/anggota/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'EditAnggota',
          path: '/anggota/:id/edit',
          component: () => import('@/views/dashboard/pages/anggota/Edit'),
          meta: {
            requiresAuth: true,
          },
        },
        // Play
        {
          name: 'Index Play',
          path: '/play',
          component: () => import('@/views/dashboard/pages/play/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Create Play',
          path: '/play/create',
          component: () => import('@/views/dashboard/pages/play/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Edit Playlist',
          path: '/play/:id/edit',
          component: () => import('@/views/dashboard/pages/play/Edit'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Edit Live',
          path: '/play/:id/edit/live',
          component: () => import('@/views/dashboard/pages/play/LiveEdit'),
          meta: {
            requiresAuth: true,
          },
        },
        // Pesan
        {
          name: 'Pesan',
          path: '/pesan',
          component: () => import('@/views/dashboard/pages/pesan/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Pesan',
          path: '/pesan/create',
          component: () => import('@/views/dashboard/pages/pesan/Create'),
          meta: {
            requiresAuth: true,
          },
        },
        // Transaksi
        {
          name: 'Transaksi',
          path: '/transaksi',
          component: () => import('@/views/dashboard/pages/transaction/Index'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
})
