// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueToast from 'vue-toast-notification'
import Embed from 'v-video-embed'

// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'

import './registerServiceWorker'
import DatetimePicker from 'vuetify-datetime-picker'

// global register
Vue.use(Embed)
moment.tz.setDefault('Asia/Jakarta')
// Vue.prototype.$moment = moment
Vue.use(VueToast)
// Vue.use(require('vue-moment'))
Vue.use(VueMoment, {
  moment,
})

Vue.use(DatetimePicker)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.loggedIn) {
      next({
        name: 'Dashboard',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
navigator.serviceWorker
  .register('firebase-messaging-sw.js')
  .then(function (registration) {
    console.log('Service Worker Registered!', registration)
  })
  .catch(function (err) {
    console.error('Service Worker registration failed', err)
  })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
